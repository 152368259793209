import React from "react";
import styled from "styled-components"
import StatsContext, { StatsContextType } from "../context/StatsContext";
import PolitykaPrywatnosci from "./consents/polityka_prywatnosci";
import WarunkiKorzystania from "./consents/warunki_korzystania";
import { useConsentSketchConfig } from "consent-sketch";

function Footer() {
    const props = React.useContext(StatsContext) as StatsContextType;
    
    const useNewFeatures: boolean = process.env.REACT_APP_USE_NEW_FEATURES === 'true';

    let {
        setShowCustomize
    } = useConsentSketchConfig();

    return <div>
        <hr></hr>
        <FooterStrapDiv>
            {/* <a href={process.env.PUBLIC_URL + "/assets/consents/polityka_prywatnosci.txt"}>Polityka prywatności */}
            <ButtonToLink onClick={e => props.setCurrentView(PolitykaPrywatnosci.VIEW_NAME)}>Polityka prywatności
            </ButtonToLink>
            &nbsp;
            <ButtonToLink onClick={e => props.setCurrentView(WarunkiKorzystania.VIEW_NAME)}>Warunki korzystania
            </ButtonToLink>
            {useNewFeatures && <>
                &nbsp;
                <ButtonToLink onClick={e => setShowCustomize(true)}>Udzielone zgody
                </ButtonToLink>
            </>}
        </FooterStrapDiv>
    </div>
}

const FooterStrapDiv = styled.div`
    width: 100%;
    padding-bottom: 10px;
    text-align: center;
`

const ButtonToLink = styled.button`
    fontSize: "1em";
    textAlign: "left";
    color: 'blue';
    background: 'none';
    margin: 0px 5px;
    padding: 8px 15px;
    border: 'none';
    cursor: 'pointer;
`

export default Footer;