import React from "react";
import StatsContext, { StatsContextType } from "../../context/StatsContext";
import { Event, createEvent } from "../../type/Event";

export const PASS_ON_EVENT: string = "passOn";
export const PASS_GET_EVENT: string = "passGet";
export function PassEventEdit() {

    const props = React.useContext(StatsContext) as StatsContextType;

    const getPassEvent = (passType: string): Event|undefined => {
        let evs = props.events.filter(e => e.pass === passType);
        if (evs.length > 0) {
            return evs[0];
        } else {
            return undefined;
        }
    }

    let passOnEvent: Event|undefined = getPassEvent(PASS_ON_EVENT);
    let passGetEvent: Event|undefined = getPassEvent(PASS_GET_EVENT);

    const setUsePassEvent = (useIt: boolean) => {
        if (!useIt) {
            props.setEvents(props.events.filter(e => !e.pass));
        } else {
            let ev1 = createEvent();
            ev1.pass = PASS_ON_EVENT;
            ev1.name = "Podanie";

            let ev2 = createEvent();
            ev2.pass = PASS_GET_EVENT;
            ev2.name = "Przyjęcie";

            props.setEvents([...props.events, ev1, ev2]);
        }
    }

    const modifyPassEvent = (passType: string, newEvent: Event) => {
        let evs = [...props.events];
        for (let i = 0; i < evs.length; i++) {
            if (evs[i].pass === passType) {
                evs[i] = newEvent;
            }
        }
        props.setEvents(evs);
    }
    
    return <div>
        <input type="checkbox" id="tracePass" name="tracePass" checked={passOnEvent != undefined || passGetEvent != undefined} onChange={e => setUsePassEvent(e.target.checked)}></input>
        <label htmlFor="tracePass">Śledź podania </label>
        {(passOnEvent != undefined || passGetEvent != undefined) && <> jako
                <input value={passOnEvent!.name} placeholder="Jak nazwać przekazanie piłki?" onChange={e => modifyPassEvent(PASS_ON_EVENT, {...passOnEvent!, name:e.target.value})}/>
                a przyjęcie jako
                <input value={passGetEvent!.name} placeholder="Jak nazwać przyjęcie?" onChange={e => modifyPassEvent(PASS_GET_EVENT, {...passGetEvent!, name:e.target.value})}/>
            </>
        }
    </div>

}
export default PassEventEdit;