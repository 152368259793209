import React from "react";
import StatsContext, { StatsContextType } from "../context/StatsContext";
import EditEvents from "./editEvents/EditEvents";
import Header from "./Header";
import GameView from "./gameView/GameView";
import LoadView from "./LoadView";
import { useSearchParams } from "react-router-dom";
import StatisticsView from "./statistics/StatisticsView";
import EditPlayers from "./EditPlayers";
import Ideas from "./Ideas";
import Footer from "./Footer";
import styled from "styled-components";
import PolitykaPrywatnosci from "./consents/polityka_prywatnosci";
import WarunkiKorzystania from "./consents/warunki_korzystania";
import { CookieDialog, useConsentSketchConfig } from "consent-sketch";
import useConsentSketch from "consent-sketch/dist/component/ConsentSketch";

function MainPage() {
    const props = React.useContext(StatsContext) as StatsContextType;
    const {setCookies} = useConsentSketch();
    const [searchParams] = useSearchParams();

    const useNewFeatures: boolean = process.env.REACT_APP_USE_NEW_FEATURES === 'true';

    let {
        setLastConsentChanges
    } = useConsentSketchConfig();

    React.useEffect(() => {
        setLastConsentChanges(new Date(2024, 1, 17, 0, 0, 0));

        setCookies([
            {title: "Zgoda i autoryzacje", key: "general", required: true, description: <p>Te pliki są niezbędne do działania naszego serwisu. Są to funkcje bezpieczeństwa, Państwa zgody.</p>},
            {title: "Komfort", key: "comfort", description: <p>Te pliki służą do ulepszonej wygody korzystania z serwisu np.: zapamiętywanie poszczególnych danych (jak raporty, drużyny, akcje) pomiędzy sesjami, żeby nie trzeba było ich wprowadzać ponownie.</p>},
        ]);
    }, [setLastConsentChanges]);

    const downloadFile = async (fileName: string) => {
        try {
            const response = await fetch(process.env.REACT_APP_BE_SERVER + `/getGame?gameId=${encodeURIComponent(fileName)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        
            if (!response.ok) {
                throw new Error(`Failed to download file: ${response.statusText}`);
            }
        
            const jsonContent = await response.json();
            props.loadFile(jsonContent, false);
        } catch (error: any) {
            console.error('Error downloading file:', error.message);
        }
    }

    React.useEffect(() => {
        let game = searchParams.get("game");
        if (game != undefined) {
            downloadFile(game).then(() => {
                props.setCurrentModalView(null);
                props.setCurrentView(StatisticsView.VIEW_NAME); 
            });
        }
    }, []);

    return <MainPageDiv>
        {useNewFeatures && <>
            <CookieDialog
                dialogContent={<><p><b>Zasady dotyczące plików cookie</b></p>
                <p>Pliki cookie są niezbędne do działania serwisu, ale też dostarczają przydatnych funkcji i 
                    służą do pomiaru wydajności w celu poprawy komfortu użytkowników.
                    Więcej informacji można znaleźć w naszej
                    <a href="#" onClick={e => props.setCurrentView(PolitykaPrywatnosci.VIEW_NAME)}> Polityce prywatności</a>.</p></>}
                configContent="Do obsługi serwisu używamy różnych plików cookie. Poniżej możesz wybrać tylko te, na które wyrażasz zgodę:"
                acceptAllBtn="Akceptuję wszystkie"
                acceptSomeBtn="Akceptuję częściowo..."
                rejectBtn="Odmawiam"
                closeSettingsBtn="Zamknij ustawienia"
                // forceDisplay={true}
            ></CookieDialog>
        </>}
        <Header></Header>
        <MainBodyDiv>
            {props.currentModalView === null && <>
                {props.currentView === EditEvents.VIEW_NAME && <EditEvents></EditEvents>}
                {props.currentView === EditPlayers.VIEW_NAME && <EditPlayers></EditPlayers>}
                {props.currentView === GameView.VIEW_NAME && <GameView></GameView>}
                {props.currentView === LoadView.VIEW_NAME && <LoadView></LoadView>}
                {props.currentView === StatisticsView.VIEW_NAME && <StatisticsView></StatisticsView>}
                {props.currentView === Ideas.VIEW_NAME && <Ideas></Ideas>}
                {props.currentView === PolitykaPrywatnosci.VIEW_NAME && <PolitykaPrywatnosci></PolitykaPrywatnosci>}
                {props.currentView === WarunkiKorzystania.VIEW_NAME && <WarunkiKorzystania></WarunkiKorzystania>}
            </>}
            {props.currentModalView !== null && <>
                {props.currentModalView === LoadView.VIEW_NAME && <LoadView></LoadView>}
            </>}
        </MainBodyDiv>
        {props.currentView != GameView.VIEW_NAME && <Footer></Footer>}
    </MainPageDiv>
}


const MainBodyDiv = styled.div`
    flex: 1;
`

const MainPageDiv = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    width: 100%
`

export default MainPage;

