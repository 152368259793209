import React from "react";
import StatsContext, { StatsContextType } from "../../../context/StatsContext";
import '../GameView.css';
import { Player } from "../../../type/Player";
import { Event } from "../../../type/Event";
import { UserAction } from "../../../type/UserAction";
import { EventProperty } from "../../../type/EventProperty";

interface EventsColumnStaticProps {
    wholeTeam: boolean,
    lastAction: UserAction|undefined
}
function EventsColumnStatic({wholeTeam, lastAction}: EventsColumnStaticProps) {
    const props = React.useContext(StatsContext) as StatsContextType;

    let lastGlobalAction: UserAction|undefined = props.actions.length == 0 ? undefined: props.actions[props.actions.length-1];

    const disable = (ev: Event): boolean => {
        if (!wholeTeam && lastAction == undefined) return true;
		if (ev.isEntry || ev.isExit) return false;
		return props.matchStart === null || 
			(props.firstHalfEnd !== null && props.secondHalfStart === null);
	}
	const halfNo = (): number => {
		if (props.firstHalfEnd !== null) return 2;
		return 1;
	}
	const display = (ev: Event): boolean => {
		let no: number = halfNo();
		if (no === 1 && !ev.firstHalf) {
			return false;
		}
		if (no === 2 && !ev.secondHalf) {
			return false;
		}
        if (wholeTeam !== (ev.teamLevel?? false)) {
            return false;
        }
        if (ev.pass) {
            return false;
        }
		return true;
	}

	const onUserAction = (event: Event) => {
        if (wholeTeam) {
            props.addAction(event, "team");
        } else {
            if (lastAction == undefined) return;
            let playerId: string = lastAction.playerId;
            if (lastAction.name == "") {
                let action = {...lastAction};
                action.name = event.name;
                action.properties = {};
                props.modifyLastAction(action);
            } else {
                props.addAction(event, playerId);
            }
            let p: Player = {... props.getPlayer(playerId)!};
            if (p != null && ((event.isEntry?? false) || (event.isExit?? false))) {
                if (event.isEntry?? false) {
                    p.onField = true;
                }
                if (event.isExit?? false) {
                    p.onField = false;
                }
                let pls: Player[] = [...props.players];
                pls[props.getPlayerNo(playerId)] = p;
                props.setPlayers(pls);
            }
        }
	}

	const setLastActionProperty = (event: Event, propertyNo: number, propertyValue: EventProperty) => {
        if (lastAction == undefined) return;
        let action: UserAction = {...lastAction};

        if (action.name != event.name && lastAction ) {
            action.properties = {};
            action.name = event.name;
        } else {
            action.properties = {...action.properties};
        }
        action.properties["prop" + propertyNo] = propertyValue.name;
        props.modifyLastAction(action);


        if (action.name == event.name && lastAction == lastGlobalAction) {
            action.properties = {...action.properties};
            action.properties["prop" + propertyNo] = propertyValue.name;
            props.modifyLastAction(action);
        } else {
            if (lastAction == lastGlobalAction) {
                action.name = event.name;
                action.properties = {};
                action.properties["prop" + propertyNo] = propertyValue.name;
                props.modifyLastAction(action);    
            } else {
                let properties: any = {};
                properties["prop" + propertyNo] = propertyValue.name;
                props.addAction(event, wholeTeam ? "team" : lastAction.playerId, properties);
            }
        }

	}

    const markSelected = (propNo: number, propertyValue: EventProperty): boolean => {
        if (lastAction == undefined || lastAction.properties == undefined || lastAction != lastGlobalAction) return false;
        return lastAction.properties["prop" + propNo] === propertyValue.name;
    }
    const markEventSelected = (event: Event) => {
        return lastAction !== undefined && lastAction == lastGlobalAction && lastAction.name === event.name;
    }

    return <>
        <div className="propertyList">
            {props.events.filter(ev => display(ev)).map((ev: Event, index: number) => <React.Fragment key={"event_" + index}>
                <div style={propertyStyle} className="propertyParent">
                    <button 
                        onClick={e => onUserAction(ev)}
                        className={markEventSelected(ev) ? "selected" : ""}
                        disabled={disable(ev)}
                    >{ev.name}</button> 
                    {ev.property !== undefined && ev.property.map((eventProp: EventProperty[], propNo: number) => <React.Fragment key={"property_" + propNo}>
                        <span className="propertyList">
                            {eventProp.map((propertyValue: EventProperty, propValueNo: number) => <React.Fragment key={"property_" + propNo + "_" + propValueNo}>
                                <button 
                                    className={markSelected(propNo, propertyValue) ? "selected" : ""}
                                    onClick={e => setLastActionProperty(ev, propNo, propertyValue)}
                                    disabled={disable(ev) || (!wholeTeam && lastGlobalAction != undefined && lastGlobalAction.name != ev.name && Object.keys(lastGlobalAction.properties).length > 0)}
                                >{propertyValue.name}</button>
                            </React.Fragment>)}
                        </span>
                    </React.Fragment>)}
                </div>
            </React.Fragment>)}
        </div>
    </>
}
export default EventsColumnStatic;

const propertyStyle = {
    marginBottom: '10px'
};