import { ConfigSketchProvider } from 'consent-sketch';
import './App.css';
import { StatsProvider } from './context/StatsContext';
import MainPage from './component/mainPage';
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient()

function App() {
  return (
    <ConfigSketchProvider>
        <QueryClientProvider client={queryClient}>
            <StatsProvider>
                <BrowserRouter>
                    <MainPage></MainPage>
                </BrowserRouter>
            </StatsProvider>
        </QueryClientProvider>
    </ConfigSketchProvider>
  );
}

export default App;

export const classNames = (classes: any) => {
  return Object.entries(classes)
    .filter(([_, value]) => value)
    .map(([key, _]) => key)
    .join(' ');
}




