// keycloak.js

import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: 'https://auth.funit.com.pl/',
  realm: 'achista',
  clientId: 'achista',
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
