import React from "react";
import StatsContext, { StatsContextType } from "../../context/StatsContext";
import { Player } from "../../type/Player";
import styled from "styled-components";
import { getUserActionProperties, getUserActionTime } from "../../type/UserAction";
import EditReport from "./EditReport";
import { StatisticsReport } from "../../type/Report";
import ReportDefinition from "./ReportDefinition";
import { parseExpression } from "./Parsing";
import { SortingState, createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";


const newReport = (): StatisticsReport => {
    return {name: "empty", order: "", filter: "", columns:[]}
}

function StatisticsView() {
    const props = React.useContext(StatsContext) as StatsContextType;
    const [showDetails, setShowDetails] = React.useState<any>({});
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [showDefinition, setShowDefinition] = React.useState<boolean>(false);
    const [statisticsReport, setStatisticsReport] = React.useState<StatisticsReport|undefined>(undefined);
    const [refresh, setRefresh] = React.useState<boolean>(false);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [plsToDisplay, setPlsToDisplay] = React.useState<Player[]>([]);
    
    React.useEffect(() => {
        if (statisticsReport === undefined && (props.reports ?? []).length > 0) {
            setStatisticsReport(props.reports[0]);
        }
        if (statisticsReport !== undefined) {
            setPlsToDisplay(props.players.filter(p => formulaValue(p, statisticsReport.filter)));
        }
    }, [statisticsReport?.filter]);

    React.useEffect(() => {
        setSorting([]);
    }, [editMode]);

    React.useEffect(() => {
        let newShowDetails: any = {};
        for (let i = 0; i < props.players.length; i++) {
            newShowDetails[props.players[i].id] = false;
        }
        setShowDetails(newShowDetails);
    }, [statisticsReport]);

    const switchDetails = (details: any, setDetails: any, pId: string) => {
        let showDet = {...details};
        showDet[pId] = !showDet[pId];
        setDetails(showDet);
    }

    const hasActions = (pId: string): boolean => {
        for (let i = 0; i < props.actions.length; i++) {
            if (props.actions[i].playerId == pId){
                return true;
            }
        }
        return false;
    }

    const copyToClipboard = () => {
        if (statisticsReport === undefined) return;
        let csvVal = "";
        let colSep = "\t";
        let rowSep = "\r\n";
        for (let i = 0; i < statisticsReport.columns.length; i++) {
            if (i > 0) {
                csvVal += colSep;
            }
            csvVal += statisticsReport.columns[i].header;
        }
        let pls = props.players
            .filter(p => formulaValue(p, statisticsReport.filter))
            .sort(orderByFormula)

        for (let pNo = 0; pNo < pls.length; pNo++) {
            csvVal += rowSep;
            for (let i = 0; i < statisticsReport.columns.length; i++) {
                if (i > 0) {
                    csvVal += colSep;
                }
                csvVal += formulaValue(pls[pNo], statisticsReport.columns[i].formula);
            }
        }
        navigator.clipboard.writeText(csvVal);
    }

    const formulaValue = (p: Player, formula: string): any => {
        try {
            let expression = parseExpression(formula, p, () => props);
            let ret = expression.evaluate();
            if (typeof ret  === 'function') {
                return "???";
            }
            if (Number.isNaN(ret)) {
                return "";
            }
            return ret;
        } catch (e) {
            return "???";
        }
    }

    const orderByFormula = (p1: Player, p2: Player) => {
        if (statisticsReport === undefined) return 0;

        let v1 = formulaValue(p1, statisticsReport.order);
        let v2 = formulaValue(p2, statisticsReport.order);
        let vi1 = parseInt(v1);
        let vi2 = parseInt(v2);
        if (!Number.isNaN(vi1) && !Number.isNaN(vi2) && typeof vi1 === typeof vi2 && typeof vi2 === typeof 0) {
            v1 = vi1;
            v2 = vi2;
        }
        if (v1 == v2) return 0;
        if (v1 > v2) return 1;
        return -1;
    }

    const deleteReport = () => {
        if (statisticsReport === undefined) return;

        if (window.confirm("Chcesz utracić definicję raportu \"" + statisticsReport.name + "\"?")) {
            let newReps = (props.reports ?? []).filter(rep => rep.name !== statisticsReport.name);
            props.setReports(newReps);
            if (newReps.length > 0) {
                setStatisticsReport(newReps[0]);
                setRefresh(!refresh); 
            }
        }
    }

    const saveReport = (r: StatisticsReport, originalName?: string) => {
        let nameToSearch = originalName ?? r.name;
        let index = -1;
        for (let i = 0; i < props.reports.length; i++) {
            if (props.reports[i].name == nameToSearch) {
                index = i;
            }
        }
        let reps
        if (index === -1) {
            reps = [...props.reports, r];
        } else {
            reps = [...props.reports];
            reps[index] = r;
        }
        props.setReports(reps);
    }

    const changedReport = (r: StatisticsReport, originalName?: string, autosave: boolean = true) => {
        setStatisticsReport(r);
        if (autosave) {
            saveReport(r, originalName);
        }
        setPlsToDisplay(props.players.filter(p => formulaValue(p, r.filter)));
    }

    const columnHelper = createColumnHelper<any>()
    const defaultColumns: any[] = [
        columnHelper.display({
            id: 'actions',
            cell: ({row}) => <>{hasActions(row.original.id) && <button onClick={e => switchDetails(showDetails, setShowDetails, row.original.id)}>{showDetails[row.original.id] ? "Ukryj szczegóły" : "Pokaż więcej"}</button>}</>,
            enableResizing: false,
            minSize: 100,
            maxSize: 120,
        }),
    ]
    if (statisticsReport !== undefined) {
        for (let i = 0; i < statisticsReport.columns.length; i++) {
            let col = statisticsReport.columns[i];
            defaultColumns.push(
                columnHelper.accessor('column' + i, {
                    header: col.header,
                    cell: info => formulaValue(info.row.original, col.formula),
                    sortingFn: (rowA, rowB) => {
                        let valA = formulaValue(rowA.original, col.formula);
                        let valB = formulaValue(rowB.original, col.formula);
                        let numA = Number((valA + "").replaceAll("%",""));
                        let numB = Number((valB + "").replaceAll("%",""));
                        if (!isNaN(numA) && !isNaN(numB)) {
                            valA = numA;
                            valB = numB;
                        }

                        if (valA == valB) return 0;
                        return valA < valB ? -1 : 1
                    }
                })
            );
        }
    }

    const table = useReactTable({
        data: plsToDisplay,
        columns: defaultColumns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
    })

    return <div>
        <DivCenter>
            {statisticsReport && <>
                {props.reports.map((r, rNo) => 
                    <button key={"showReport" + rNo} className={statisticsReport.name == r.name ? "selected" : ""} onClick={e => {setRefresh(!refresh); changedReport(r)}}>{r.name}</button>
                )}
            </>}
            <span className="littleSpace"></span>
            <button onClick={e => copyToClipboard()} disabled={showDefinition || editMode}>Kopiuj do schowka</button>
            <button className={editMode ? "selected" : ""} onClick={e => {setEditMode(!editMode); setShowDefinition(false);}}>Edycja</button>
            <button className={showDefinition ? "selected" : ""} onClick={e => {setEditMode(false); setShowDefinition(!showDefinition);}}>Definicja raportu</button>
            
            <button onClick={e => deleteReport()} disabled={!showDefinition && !editMode}>Usuń raport</button>
            <button onClick={e => {changedReport(newReport(), undefined, true); setEditMode(true); setRefresh(!refresh)}}>Nowy raport</button>

        </DivCenter>

        {statisticsReport && <>
            {showDefinition && <>
                <ReportDefinition report={statisticsReport} setReport={changedReport} refresh={refresh}></ReportDefinition>
            </>}
            {editMode && <>
                <EditReport report={statisticsReport} saveReport={changedReport}></EditReport>
            </>}
        </>}

        {statisticsReport && <>
            <Table>
                <thead>
                    {table.getHeaderGroups().map(headerGroup =>
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}
                                    className={(!editMode && header.column.getCanSort()) ? 'cursor-pointer' : ''}
                                    onClick={e => {if (!editMode) header.column.getToggleSortingHandler()!(e)}}
                                    style={{ width: `${header.getSize()}px` }}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext())
                                    }
                                    {
                                        {
                                            asc: '↓',
                                            desc: '↑',
                                        }[header.column.getIsSorted() as string] ?? null
                                    }
                                </th>
                            ))}
                        </tr>
                    )}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(({original: player, getVisibleCells}) => <React.Fragment key={player.id}>
                        <tr>
                            {getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>

                        {showDetails[player.id] && <DetailsListRow><td colSpan={statisticsReport.columns.length + 1}>
                            {props.actions.filter(usrAction => usrAction.playerId == player.id).map((userAction, index) => 
                                <div key={index + "__" + userAction.time.toString()}>
                                    {getUserActionTime(props, userAction) 
                                        + " -> " + userAction.name + getUserActionProperties(userAction)}
                                </div>
                            )}
                        </td></DetailsListRow>}
                    </React.Fragment>)}
                </tbody>
            </Table> 
        </>}
    </div>
}
StatisticsView.VIEW_NAME = "StatisticsView";
export default StatisticsView;

const DetailsListRow = styled.tr`
    td {
        text-align: center;
    }
`

const Table = styled.table`
    width: 100%;

    td {
        text-align: center;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    button {
        white-space: nowrap;
    }
`

const DivCenter = styled.div`
    text-align: center;
`