import React from "react";
import StatsContext, { StatsContextType } from "../context/StatsContext";
import dayjs from "dayjs";
import EditEvents from "./editEvents/EditEvents";
import GameView from "./gameView/GameView";
import LoadView from "./LoadView";
import './Header.css';
import StatisticsView from "./statistics/StatisticsView";
import EditPlayers from "./EditPlayers";
import Login from "../Login";

function Header() {
    const props = React.useContext(StatsContext) as StatsContextType;

    const useNewFeatures: boolean = process.env.REACT_APP_USE_NEW_FEATURES === 'true';


    const testReq = (url: string, auth?: boolean) => {
        let headers: any = {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'GET', // Include the requested method
            'Access-Control-Request-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': "*",
        };
         
        if (props.keycloak != null && auth) {
            headers['Authorization'] = 'Bearer ' + props.keycloak.token;
        }

        fetch(process.env.REACT_APP_BE_SERVER + url, {
            method: 'GET',
            headers: headers,
        // }).then((response) => {
        //     response.text().then(txt => {
        //         console.log(txt);
        //     });
        }).then((response) => response.text()
        ).then(response => {
            console.log(response);
        }).catch((error) => {
            console.error(error);
        });
    }

    return <div>
        <span className="littleSpace"></span>
        <button onClick={e => props.setCurrentView(LoadView.VIEW_NAME)}>Menu</button>
        <span className="littleSpace"></span>

        <button className={props.currentView === EditEvents.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(EditEvents.VIEW_NAME)}>Akcje</button>
        <button className={props.currentView === EditPlayers.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(EditPlayers.VIEW_NAME)}>Zawodnicy</button>
        <button className={props.currentView === GameView.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(GameView.VIEW_NAME)}>Widok gry</button>
        <button className={props.currentView === StatisticsView.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(StatisticsView.VIEW_NAME)}>Statystyki</button>

        {props.currentView === GameView.VIEW_NAME && <span className="headerCenter">
            <span className="littleSpace"></span>
            {props.matchStart === null && <button id="matchStart" onClick={e => props.setMatchStart(dayjs())}>Początek meczu</button>}
            {props.firstHalfEnd === null && props.matchStart !== null && <button id="halfEnd" onClick={e => {props.setFirstHalfEnd(dayjs())}}>Koniec I połowy</button>}
            {props.secondHalfStart === null && props.firstHalfEnd !== null && <button id="sndHalfStart" onClick={e => props.setSecondHalfStart(dayjs())}>Początek II polowy</button>}
            {props.matchEnd === null && props.secondHalfStart !== null && <button id="matchEnd" onClick={e => props.setMatchEnd(dayjs())}>Koniec meczu</button>}
        </span>}

        {useNewFeatures && <>
            <button onClick={e => testReq('/test', true)}>test</button>
            <button onClick={e => testReq('/public/test')}>public test</button>
            
            <span className="headerRight">
                {props.currentView === GameView.VIEW_NAME && <span>
                    <button onClick={e => props.setGameView("perPlayer")}>View per player</button>
                    <button onClick={e => props.setGameView("static")}>Static view</button>
                    <span className="littleSpace"></span>
                </span>}
                <Login></Login>
            </span>
        </>}
        {/* <AudioInput></AudioInput> */}
    </div>
}
export default Header;