import { StatsContextType } from "../../context/StatsContext";
import { Player } from "../../type/Player";
import { UserAction } from "../../type/UserAction";

// //https://github.com/silentmatt/expr-eval/tree/master#custom-javascript-functions
const Parser = require('expr-eval').Parser;

export const actionHasProperty = (action: UserAction, propValue: string) => {
    if (action.properties !== undefined) {
        for (const propKey in action.properties) {
            if (action.properties.hasOwnProperty(propKey) && action.properties[propKey] !== undefined && action.properties[propKey] == propValue) {
                return true
            }
        }
    }
    return false;
}

export function parseExpression(formula: string, p: Player|null, getProps: () => StatsContextType) {
    const parser = new Parser();

    let firstPart = (action: UserAction) => {
        if (getProps().secondHalfStart == undefined) return true;
        return action.time < getProps().secondHalfStart!;
    }
    let secondPart = (action: UserAction) => {
        if (getProps().secondHalfStart == undefined) return false;
        return action.time >= getProps().secondHalfStart!;
    }

    let checkParam = (param: String, action: UserAction): boolean => {
        if (param.charAt(0) == '+') {
            return actionHasProperty(action, param.substring(1));
        }
        if (param.charAt(0) == '-') {
            return !actionHasProperty(action, param.substring(1));
        }
        if (param == 'firstHalf' || param == 'firstPart') {
            return firstPart(action);
        }
        if (param == 'secondHalf' || param == 'secondPart') {
            return secondPart(action);
        }
        return false;
    }

    parser.functions.noOf = (...params: any) => {
        if (params == undefined || params.length === 0) return "";
        let ret: number = 0;
        for (let i = 0; i < getProps().actions.length; i++) {
            let action: UserAction = getProps().actions[i];
            if (action.playerId == p!.id
                    && action.name == params[0]) {
                let ok: boolean = true;
                for (let j = 1; j < params.length; j++) {
                    ok = ok && checkParam(params[j], action);
                }
                if (ok) {
                    ret++;
                }
            }
        }
        return ret;
    };

    parser.functions.player = (...params: any) => {
        if (params.length !== 1) return "Podaj atrybut gracza";
        let paramName = params[0];
        if (paramName === "Obecny") paramName = "present";
        if (paramName === "Numer w Excel'u") paramName = "excelNo";
        if (paramName === "Numer na koszulce") paramName = "shirtNo";
        if (paramName === "Imię") paramName = "name";
        if (paramName === "Nazwisko") paramName = "familyName";
        if (paramName === "Pozycja") paramName = "pozycja";
        let att: keyof Player = paramName;
        return p![att] ?? "";
    }

    parser.functions.name = (...params: any) => {
        return p?.name;
    }

    parser.functions.familyName = (...params: any) => {
        return p?.familyName;
    }

    parser.functions.tshirt = (...params: any) => {
        if (params.length === 0) {
            return p?.shirtNo;
        }
        if (params[0] === "Numer w Excelu" || params[0] === "Numer w Excel'u") {
            return p?.excelNo;
        }
        return p?.shirtNo;
    }

    parser.functions.position = (...params: any) => {
        return p?.pozycja;
    }

    parser.functions.concat = (...params: any): string => {
        let ret: string = "";
        for (let i = 0; i < params.length; i++){
            if (typeof params[i] === "number") {
                if (!Number.isNaN(params[i])) {
                    ret += Math.round(params[i]);
                }
            } else {
                ret += params[i];
            }
        }
        return ret;
    }

    parser.functions.if = (...params: any): any => {
        if (params.length != 3) return "";
        if (params[0]) return params[1];
        return params[2];
    }

    try {
        let ret = parser.parse(formula);        
        return (typeof ret === "number") ? Math.round(ret) : ret;
    } catch (e) {
        return undefined;
    }
}
