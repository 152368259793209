import React from "react";
import StatsContext, { StatsContextType } from "../../../context/StatsContext";
import '../GameView.css';
import { Player } from "../../../type/Player";
import { Event, getEventDefinition } from "../../../type/Event";
import { UserAction } from "../../../type/UserAction";
import { EventProperty } from "../../../type/EventProperty";

interface EventsColumnProps {
    isWholeTeamColumn: boolean,
    player?: Player,
    playerId: string,
}
function EventsColumn({isWholeTeamColumn, player, playerId}: EventsColumnProps) {
    const props = React.useContext(StatsContext) as StatsContextType;

    const disable = (ev: Event): boolean => {
		if (ev.isEntry || ev.isExit) return false;
		return props.matchStart === null || 
			(props.firstHalfEnd !== null && props.secondHalfStart === null);
	}
	const halfNo = (): number => {
		if (props.firstHalfEnd !== null) return 2;
		return 1;
	}
	const display = (ev: Event): boolean => {
		let no: number = halfNo();
		if (no === 1 && !ev.firstHalf) {
			return false;
		}
		if (no === 2 && !ev.secondHalf) {
			return false;
		}
        if (isWholeTeamColumn !== (ev.teamLevel?? false)) {
            return false;
        }
		return true;
	}

	const onUserAction = (event: Event) => {
        if (isWholeTeamColumn) {
            props.addAction(event, "team");
        } else {
            let p: Player = {...player!};	
            props.addAction(event, playerId!);
            if ((event.isEntry?? false) || (event.isExit?? false)) {
                if (event.isEntry?? false) {
                    p.onField = true;
                }
                if (event.isExit?? false) {
                    p.onField = false;
                }
                let pls: Player[] = [...props.players];
                pls[props.getPlayerNo(playerId)] = p;
                props.setPlayers(pls);
            }
        }
	}

	const setLastActionProperty = (propertyNo: number, propertyValue: EventProperty) => {
        let action: UserAction = {...props.actions[props.actions.length-1]};
        action.properties = {...action.properties};
        action.properties["prop" + propertyNo] = propertyValue.name;
        props.modifyLastAction(action);
	}

    const markSelected = (propNo: number, propertyValue: EventProperty): boolean => {
        return props.actions[props.actions.length-1].properties["prop" + propNo] === propertyValue.name;
    }

    let lastAction: UserAction|undefined = props.actions !== undefined ? props.actions[props.actions.length-1] : undefined;
	let event: Event|undefined =
        (props.actions !== undefined && props.actions.length > 0) 
            ? getEventDefinition(props, props.actions[props.actions.length-1].name)
            : undefined;

    return <td>
        <div className="propertyList">
            {props.events.filter(ev => display(ev)).map((ev: Event, index: number) => <React.Fragment key={"event_" + playerId + "_" + index}>
                <button onClick={e => onUserAction(ev)} disabled={disable(ev)}>{ev.name}</button>
            </React.Fragment>)}
        </div>
        {event !== undefined && ((isWholeTeamColumn && (event.teamLevel??false)) || (!isWholeTeamColumn && lastAction != undefined && lastAction.playerId === playerId)) && <>
            <div className="propertyParent">
                {event.property !== undefined && event.property.map((eventProp: EventProperty[], propNo: number) => <React.Fragment key={"property_" + playerId + "_" + propNo}>
                    <span className="propertyList">
                        {eventProp.map((propertyValue: EventProperty, propValueNo: number) => <React.Fragment key={"property_" + playerId + "_" + propNo + "_" + propValueNo}>
                            <button className={markSelected(propNo, propertyValue) ? "selected" : ""} onClick={e => setLastActionProperty(propNo, propertyValue)}>{propertyValue.name}</button>
                        </React.Fragment>)}
                    </span>
                </React.Fragment>)}
            </div>
        </>}
    </td>
}
export default EventsColumn;