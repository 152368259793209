import React from "react";
import StatsContext, { StatsContextType } from "../../../context/StatsContext";
import { Player } from "../../../type/Player";
import TeamRow from "./TeamRow";
import PlayerRow from "./PlayerRow";

function GameViewPerPlayer() {
    const props = React.useContext(StatsContext) as StatsContextType;

    return <div id="gameTableDiv">
        <table className="gameView">
            <thead>
                <tr>
                    <th>Imie <button onClick={e => props.addPlayer()}>Add</button></th>
                    <th>Akcja</th>
                    <th>Statystyki</th>
                </tr>
            </thead>
            <tbody id="mainTable">
				<TeamRow></TeamRow>
                {props.players.map((p: Player, pNo: number) => <React.Fragment key={"playerOn" + pNo}>
					{(p.onField??false) && <PlayerRow player={p} playerId={p.id}></PlayerRow>}
				</React.Fragment>)}
                {props.players.map((p: Player, pNo: number) => <React.Fragment key={"benchPlayer" + pNo}>
				    {!(p.onField??false) && <PlayerRow player={p} playerId={p.id}></PlayerRow>}
				</React.Fragment>)}
            </tbody>
        </table>
    </div>
}
export default GameViewPerPlayer;