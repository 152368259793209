import React, { useState } from 'react';
import { Player } from '../type/Player';
import { Event, createEvent } from '../type/Event';
import dayjs, { Dayjs } from 'dayjs';
import { UserAction, createUserAction } from '../type/UserAction';
import LoadView from '../component/LoadView';
import { StatisticsReport } from '../type/Report';
import { extendedViewDefinition, goalKeepersViewDefinition, simpleViewDefinition } from '../component/statistics/ReportDefinition';
import keycloakRef from '../keycloak';

const createInitialEvents = (): Event[] => {
    let evs: Event[] = [];
    let evEntry = createEvent();
    evEntry.name = "Wejście";
    evEntry.isEntry = true;

    let evExit = createEvent();
    evExit.name = "Zejście";
    evExit.isExit = true;

    evs.push(evEntry, evExit);
    return evs;
}

export const mergeIn = (arr1: UserAction[], arr2: UserAction[], name: string): UserAction[] => {
    let result: UserAction[] = [];
    let i1 = 0;
    let i2 = 0;
    let mkCopy = (arr: UserAction[], no: number) => {
        let ev = JSON.parse(JSON.stringify(arr[no]));
        ev.time = dayjs(ev.time);
        ev.player = name;
        return ev;
    }
    if (arr2 === undefined) {
        return arr1;
    }
    while (i1 < arr1.length && i2 < arr2.length) {
        if (arr1[i1].time < arr2[i2].time) {
            result.push(arr1[i1]);
            i1++;
        } else {
            result.push(mkCopy(arr2, i2));
            i2++;
        }
    }
    while (i1 < arr1.length) {
        result.push(arr1[i1]);
        i1++;
    }
    while (i2 < arr2.length) {
        result.push(mkCopy(arr2, i2));
        i2++;
    }
    return result;
}

export function StatsProvider({ children }: any) {
    const version: number =  parseInt((process.env.REACT_APP_VERSION?? "0.0.0").split(".")[1], 10);
    const [currentModalView, setCurrentModalView] = React.useState<string|null>(null);
    const [currentView, setCurrentView] = React.useState<string>(LoadView.VIEW_NAME);
    const [events, setEvents] = React.useState<Event[]>(createInitialEvents());
    const [players, setPlayers] = React.useState<Player[]>([]);

    const [matchStart, setMatchStart] = React.useState<Dayjs|null>(null);
    const [firstHalfEnd, setFirstHalfEnd] = React.useState<Dayjs|null>(null);
    const [secondHalfStart, setSecondHalfStart] = React.useState<Dayjs|null>(null);
    const [matchEnd, setMatchEnd] = React.useState<Dayjs|null>(null);
    const [gameView, setGameView] = React.useState<string>("static");

    const [actions, setActions] = React.useState<UserAction[]>([]);
    const [gameId, setGameId] = React.useState<string>("");
    const [reports, setReports] = React.useState<StatisticsReport[]>([]);

    const [ballOwnerId, setBallOwnerId] = React.useState<string|undefined>(undefined);
    const [keycloak, setKeycloak] = useState<any | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    React.useEffect(() => {
        if (keycloak == null) {
            keycloakRef.init({
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                onLoad: "check-sso",
                checkLoginIframe: true
            }).then((isAuthenticated: any) => {
                setKeycloak(keycloakRef);
                setAuthenticated(isAuthenticated)
            }).catch(() => {
                console.error('Error initializing Keycloak');
            });
        }

        return () => {
            keycloak.logout();
        };
    }, []);

    const modifyLastAction = (action: UserAction) => {
        for (let i = actions.length-1; i >= 0; i--) {
            if (actions[i].actionOwner == action.actionOwner) {
                let acts: UserAction[] = [...actions];
                acts[i] = action;
                setActions(acts);
                if (action.name) {
                    let event = events.filter(ev => ev.name == action.name)[0];
                    checkTheBall(event, action.playerId);
                }
                return;
            }
        }
    }

    const checkTheBall = (event: Event|null, playerId: string) => {
        if (event != null) {
            if (event.getTheBall) {
                setBallOwnerId(playerId);
            }
            if (event.looseTheBall) {
                setBallOwnerId(undefined);
            }
        }
    }

    const addAction = (event: Event|null, playerId: string, properties?: any) => {
        checkTheBall(event, playerId);
        let action: UserAction = createUserAction(event == null ? "" : event.name, playerId, properties);
        setActions([...actions, action]);
    }
    const deleteAction = (actionNo: number) => {
        let acts = [...actions];
        if (actionNo < 0) {
            acts.splice(acts.length - 1 + actionNo, 1);
        } else {
            acts.splice(actionNo, 1);
        }
        setActions(acts);
    }
    const deleteActionObject = (action: UserAction) => {
        for (let i = 0; i < actions.length; i++) { 
            if (action.time.isSame(actions[i].time) && action.name == actions[i].name && action.playerId == actions[i].playerId) {
                deleteAction(i);
                return;
            }
        }
    }

    const setView = (viewName: string) => {
        setCurrentView(viewName);
        setCurrentModalView(null);
    }

    const changePlayerName = (playerNo: number, newName: string): void => {
        let pls: Player[] = [...players];
        pls[playerNo] = {...pls[playerNo], name: newName};
        setPlayers(pls);
    }

    const changePlayer = (playerNo: number, newFieldValue: any, fieldName: string): void => {
        let pls: Player[] = [...players];
        pls[playerNo] = {...pls[playerNo], [fieldName]: newFieldValue};
        setPlayers(pls);
    }

    const newUUUID = (): string => {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    const addPlayer = () => {
        console.log(newUUUID());
        let p: Player = {name: "", id: "", present: true};
        let unique = false;
        while (!unique) {
            p.id = newUUUID();
            unique = true;
            for (let i = 0; i < players.length; i++) {
                if (players[i].id == p.id) {
                    unique = false;
                }
            }
            for (let i = 0; i < actions.length; i++) {
                if (actions[i].playerId == p.id) {
                    unique = false;
                }
            }
        }
        setPlayers([...players, p]);
    }
    const deletePlayer = (playerNo: number): void => {
        let pls: Player[] = [...players];
        pls.splice(playerNo, 1);
        setPlayers(pls);
    }

	const movePlayer = (index: number, moveUp: boolean): void => {
        if (index === 0 && moveUp) return;
        if (index === players.length-1 && !moveUp) return;

        let pls: Player[] = [...players];
        let el = pls[index];
        pls.splice(index, 1);
        pls.splice(index + (moveUp ? -1 : 1), 0, el);
        setPlayers(pls);
    }
    
    const createNewGameId = (): string => {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            result += chars.charAt(randomIndex);
        }
        setGameId(result);
        return result;
    }

    
    const migratePlayerDates = (playerData: any[]): any => {
        for (let i = 0; i < playerData.length; i++) {
            migrateUserActions(playerData[i].actions);
        }
        return playerData;
    }
    const migrateUserActions = (actions: any[]): any[] => {
        if (actions != undefined) {
            for (let j = 0; j < actions.length; j++) {
                actions[j].time = dayjs(actions[j].time);
            }
        }
        return actions;
    }
    const loadFile = (data: any, cleanup: boolean): void => {
        let players: Player[];
        if (data.saveVersion < 4) {
            let teamActions: UserAction[] = [];
            if (data.saveVersion < 3) {
                for (let i = 0; i < data["actions"].length; i++) {
                    data["actions"][i]["property"] = data["actions"][i]["properties"];
                    data["actions"][i]["properties"] = undefined;
                }
                setEvents(data["actions"]);
                players = migratePlayerDates(data["events"]??[]);
            } else {
                setEvents(data["events"]);
                players = migratePlayerDates(data["players"]??[]);
                teamActions = migrateUserActions(cleanup ? [] : data["teamActions"]);
            }
            if (cleanup) {
                for (let i = 0; i < players.length; i++) {
                    (players[i] as any).actions = [];
                    players[i].onField = false;
                }
            }
            if (teamActions != undefined) {
                for (let i = 0; i < teamActions.length; i++) {
                    teamActions[i].actionOwner = "team";
                    teamActions[i].playerId = "team";
                }
            }
            for (let i = 0; i < players.length; i++) {
                players[i].id = "" + i;
                if ((players[i] as any).actions !== undefined) {
                    for (let j = 0; j < (players[i] as any).actions.length; j++) {
                        (players[i] as any).actions[j].actionOwner = "player";
                        (players[i] as any).actions[j].playerId = "" + i;
                    }
                }
            }
            let acts: UserAction[] = [];
            acts = mergeIn(acts, teamActions??[], "_Cały zespół_");
            for (let i = 0; i < players.length; i++) {
                acts = mergeIn(acts, (players[i] as any).actions, players[i].name);
                (players[i] as any).actions = undefined;
            }
            setActions(acts);
        } else {
            setEvents(data["events"]);
            players = data["players"];
            if (data.saveVersion < 7) {
                for (let i = 0; i < players.length; i++) {
                    players[i].id = "" + i;
                }
            }
            if (players != undefined) {
                for (let i = 0; i < players.length; i++) {
                    if (cleanup) {
                        players[i].onField = false;
                    }
                    if (players[i].excelNo != undefined) {
                        players[i].excelNo = parseInt(players[i].excelNo + "");
                    }
                    if (players[i].shirtNo != undefined) {
                        players[i].shirtNo = parseInt(players[i].shirtNo + "");
                    }
                }
            }
            let acts: UserAction[] = cleanup ? [] : migrateUserActions(data["actions"]) ?? [];
            if (data.saveVersion < 7) {
                for (let i = 0; i < acts.length; i++) {
                    acts[i].playerId = (acts[i] as any).playerNo == -1 ? "team" : ("" + (acts[i] as any).playerNo);
                    (acts[i] as any).playerNo = undefined;
                }
            }
            setActions(acts);
        }
        if (data.saveVersion < 8 || cleanup) {
            for (let i = 0; i < players.length; i++) {
                players[i].present = true;
            }
        }
        if (data.saveVersion >= 8) {
            setReports(data.reports || []);
        } else {
            setReports([simpleViewDefinition, extendedViewDefinition, goalKeepersViewDefinition]);
        }
        setPlayers(players);

        setMatchStart((!cleanup && data["matchStart"]) ? dayjs(data["matchStart"]) : null);
        setFirstHalfEnd((!cleanup && data["halfEnd"]) ? dayjs(data["halfEnd"]) : null);
        setSecondHalfStart((!cleanup && data["sndHalfStart"]) ? dayjs(data["sndHalfStart"]) : null);
        setMatchEnd((!cleanup && data["matchEnd"]) ? dayjs(data["matchEnd"]) : null);

        if (data.version < 6 || cleanup) {
            createNewGameId();
        } else {
            setGameId(data["gameId"]);
        }
    }

    const getPlayerNo = (playerId: string): number => {
        for (let i = 0; i < players.length; i++) {
            if (players[i].id == playerId) {
                return i;
            }
        }
        return -1;
    }
    const getPlayer = (playerId: string): Player|undefined => {
        for (let i = 0; i < players.length; i++) {
            if (players[i].id == playerId) {
                return players[i];
            }
        }
        return undefined;
    }

    const context: StatsContextType = {
        version: version,
        events: events, setEvents: setEvents,
        players: players, setPlayers: setPlayers,
        matchStart: matchStart, setMatchStart: setMatchStart,
        firstHalfEnd: firstHalfEnd, setFirstHalfEnd: setFirstHalfEnd,
        secondHalfStart: secondHalfStart, setSecondHalfStart: setSecondHalfStart,
        matchEnd: matchEnd, setMatchEnd: setMatchEnd,
        currentView: currentView, setCurrentView: setView,
        currentModalView: currentModalView, setCurrentModalView: setCurrentModalView,
        gameView: gameView, setGameView: setGameView,
        gameId, setGameId, createNewGameId,
        reports, setReports,
        ballOwnerId, setBallOwnerId,

        actions, setActions, addAction,
        /**
         * Modify last action of given type (team or player)
         */
        modifyLastAction,
        
        deleteAction,
        deleteActionObject,
        
        changePlayer, changePlayerName, deletePlayer, movePlayer, addPlayer, getPlayer, getPlayerNo,

        loadFile,
        keycloak,
        authenticated
    }

    return <StatsContext.Provider value={context}>
          {children}
    </StatsContext.Provider>
};


export interface StatsContextType {
    version: number,
    events: Event[], setEvents:  React.Dispatch<React.SetStateAction<Event[]>>,
    matchStart: Dayjs|null, setMatchStart: React.Dispatch<React.SetStateAction<Dayjs | null>>,
    firstHalfEnd: Dayjs|null, setFirstHalfEnd: React.Dispatch<React.SetStateAction<Dayjs | null>>,
    secondHalfStart: Dayjs|null, setSecondHalfStart: React.Dispatch<React.SetStateAction<Dayjs | null>>,
    matchEnd: Dayjs|null, setMatchEnd: React.Dispatch<React.SetStateAction<Dayjs | null>>,
    players: Player[], setPlayers: React.Dispatch<React.SetStateAction<Player[]>>,
    currentView: string, setCurrentView: (viewName: string) => void,
    gameId: string, setGameId: React.Dispatch<React.SetStateAction<string>>,
    currentModalView: string|null, setCurrentModalView: React.Dispatch<React.SetStateAction<string|null>>,
    gameView: string, setGameView: React.Dispatch<React.SetStateAction<string>>, createNewGameId: () => string,
    reports: StatisticsReport[], setReports: React.Dispatch<React.SetStateAction<StatisticsReport[]>>,
    ballOwnerId: string|undefined, setBallOwnerId: React.Dispatch<React.SetStateAction<string|undefined>>,

    actions: UserAction[],
    setActions: React.Dispatch<React.SetStateAction<UserAction[]>>,
    addAction: (event: Event|null, playerId: string, properties?: any) => void,
    /**
     * Modify last action of given type (team or player)
     */
    modifyLastAction: (action: UserAction) => void,
    deleteAction: (actionNo: number) => void,
    deleteActionObject: (action: UserAction) => void,

    changePlayer: (playerNo: number, newFieldValue: any, fieldName: string) => void,
    changePlayerName: (playerNo: number, newName: string) => void,
    deletePlayer: (playerNo: number) => void,
    addPlayer: () => void,
	movePlayer: (index: number, moveUp: boolean) => void,
    getPlayer: (playerId: string) => Player|undefined,
    getPlayerNo: (playerId: string) => number,
    loadFile: (data: any, cleanup: boolean) => void,
    keycloak: any|null,
    authenticated: boolean,
}

const StatsContext = React.createContext<Partial<StatsContextType>>({});
export default StatsContext;