import React from "react";
import StatsContext, { StatsContextType } from "../../../context/StatsContext";
import { Player, getPlayerString } from "../../../type/Player";
import EventsColumnStatic from "./EventsColumnStatic";
import './GameViewStatic.css';
import { UserAction, createUserAction, getUserActionProperties, getUserActionTime } from "../../../type/UserAction";
import styled from "styled-components";
import BallPassAction from "../BallPassAction";
import { classNames } from "../../../App";

function GameViewStatic() {
    const props = React.useContext(StatsContext) as StatsContextType;

    let showPass = props.events.filter(e => e.pass).length > 0;
      
    //   const classes = {
    //     'maybeClass': true,
    //     'otherClass': true,
    //     'probablyNotClass': false,
    //   };
      
    //   const myClassNames = classNames(classes);
    // Output: "maybeClass otherClass"

    const getLastAction = (wholeTeam: boolean): UserAction|undefined => {
        for (let i = props.actions.length-1; i >= 0; i--) {
            if ((props.actions[i].actionOwner == "team") == wholeTeam) {
                return props.actions[i];
            }
        }
        return undefined;
    }
    let lastActionSinglePlayer: UserAction|undefined = getLastAction(false);

    const unselectBallOwner = (p: Player) => {
        if (showPass && p.id !== props.ballOwnerId) {
            props.setBallOwnerId(undefined);
        }
    }
    const tryAddingAction = (p: Player): void => {
        if (lastActionSinglePlayer != undefined && lastActionSinglePlayer.name == "") {
            let acts = [...props.actions];
            acts = acts.slice(0, acts.length-1);
            acts.push(createUserAction("", p.id, {}));
            props.setActions(acts)
        } else {
            props.addAction(null, p.id);
        }
    }

    let playerButtons = (filter: (p: Player) => boolean) => {
        let lastAction: UserAction|undefined = (props.actions != undefined && props.actions.length > 0) ? props.actions[props.actions.length-1]: undefined;
        return props.players.map((p: Player) => 
            <React.Fragment key={"player_" + p.id}>
                {filter(p) && <SingleLineDIV>
                    {p.present && p.onField && showPass && <BallPassAction player={p} tryAddingAction={tryAddingAction}></BallPassAction>}
                    <button onClick={(e)=> {unselectBallOwner(p); tryAddingAction(p);}} className={classNames({
                        "selected": lastAction !== undefined && lastAction.playerId === p.id,
                        "warning": p.present !== true
                    })}>{getPlayerString(p)}{p.present !== true && <p>(Zawodnik nieobecny)</p>}</button>
                    <br></br>
                </SingleLineDIV>}                            
            </React.Fragment>);
    }

    const isNobodyOnField = () : boolean => {
        return props.players.filter(p => p.onField).length == 0;
    }

    let isWholeTeamEvent: boolean = props.events.filter(e => e.teamLevel ?? false).length > 0;

    return <div id="gameTableDiv">
        <table className="gameView">
            <thead>
                <tr>
                    <th>Zawodnik</th>
                    <th>Akcja</th>
                </tr>
            </thead>
            <tbody id="mainTable">
                {isNobodyOnField() && <tr><CenterTD colSpan={2}>Przed rozpoczęciem meczu zaznacz kto wszedł na boisko<br></br>Na stronie 'Zawodnicy' możesz oznaczyć zawodników nieobecnych, żeby się nie pokazywali.</CenterTD></tr>}
                <tr>
                    <td>
                        {playerButtons(p => p.onField??false)}
                        <br></br>
                        {playerButtons(p => (p.present??false) && !(p.onField??false))}
                    </td>
                    <td>
                        <EventsColumnStatic wholeTeam={false} lastAction={lastActionSinglePlayer}></EventsColumnStatic>
                    </td>
                </tr>
                {isWholeTeamEvent && <tr>
                    <td>
                        Cały zespół
                    </td>
                    <td>
                        <EventsColumnStatic wholeTeam={true} lastAction={getLastAction(true)}></EventsColumnStatic>
                    </td>
                </tr>}
            </tbody>
        </table>
        <div>
            {props.actions.slice(-200).sort((a, b) => a.time.isSame(b.time) ? 0 : (a.time.isAfter(b.time) ? -1 : 1)).map((userAction, index) => {
                return <div key={index + "__" + userAction.time.toString()}>
                    <button onClick={e => props.deleteActionObject(userAction)}>Usuń</button>
                    {getUserActionTime(props, userAction) 
                        + " - " + (userAction.playerId == "team" ? "Whole team" : props.getPlayer(userAction.playerId)!.name)
                        + " -> " + userAction.name + getUserActionProperties(userAction)}
                </div>
            })}
        </div>
        {/* <div className="footer">
            <input type="text"></input>
        </div> */}
    </div>
}
export default GameViewStatic;

const CenterTD = styled.td`
    text-align: center;
`

const SingleLineDIV = styled.div`
    white-space: nowrap;
`