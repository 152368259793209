import styled from "styled-components";
import { parseExpression } from "./Parsing";
import React from "react";
import StatsContext, { StatsContextType } from "../../context/StatsContext";
import { StatisticsReport, StatisticsReportColumn } from "../../type/Report";

interface EditReportParams {
    report: StatisticsReport,
    saveReport: (report: StatisticsReport, originalName?: string, autosave?: boolean) => void
}
function EditReport({report, saveReport}: EditReportParams) {
    const props = React.useContext(StatsContext) as StatsContextType;
    const [repName, setRepName] = React.useState<string>("");

    React.useEffect(() => {
        setRepName(report.name);
    }, [report.name]);

    const changeCol = (colNo: number, col: StatisticsReportColumn) => {
        let cols = [...report.columns];
        cols[colNo] = col;
        saveReport({...report, columns: cols});
    }

    const parseFormula = (colNo: number, formula: HTMLInputElement, validate: boolean) => {
        try {
            if (formula.value !== "") {
                parseExpression(formula.value, null, () => props);
                changeCol(colNo, {...report.columns[colNo], formula: formula.value})
            } else {
                changeCol(colNo, {...report.columns[colNo], formula: ""})
            }
            formula.style.backgroundColor = "white";
        } catch (e) {
            if (validate) {
                changeCol(colNo, {...report.columns[colNo], formula: ""})
                formula.style.backgroundColor = "red";
            }
        }
    }

    const removeColumn = (colNo: number) => {
        let cols = report.columns;
        cols.splice(colNo, 1);
        saveReport({...report, columns: cols});
    }

    return <DivCenter>
        <p>
            Nazwa raportu: <input value={repName} onChange={e => setRepName(e.target.value)}></input>
            <button onClick={e => saveReport({...report, name: repName}, report.name)}>Zmień nazwę</button>
            <button onClick={e => saveReport({...report, name: repName}, repName)}>Utwórz nowy raport</button>
        </p>
        <h3>Kolumny</h3>
        <div>
            Filtr: <input value={report.filter} onChange={e => saveReport({...report, filter: e.target.value})}></input>
        </div>
        <div>
            Sortowanie: <input value={report.order} onChange={e => saveReport({...report, order: e.target.value})}></input>
        </div>
        <div>
            <p>
                <button onClick={e => saveReport({...report, columns: [...report.columns, {formula: "", header: ""}]})}>Dodaj kolumnę</button>
            </p>
            {report.columns.map((col, colNo) => <div key={"colEdit" + colNo}>
                <button onClick={e => removeColumn(colNo)}>X</button>
                Nagłówek: <input value={col.header} onChange={e => changeCol(colNo, {...col, header: e.target.value})}></input>
                Formuła: <FormulaInput onBlur={e => parseFormula(colNo, e.target, true)} value={col.formula??""} onChange={e => parseFormula(colNo, e.target, false)}></FormulaInput>
            {/* noOf("Rzut", "+Karny", "-Bramka", secondPart) * 100 / noOf("Rzut", "+Karny", secondPart) + "%" */}
            </div>)}
        </div>
    </DivCenter>
}
export default EditReport;

const DivCenter = styled.div`
    text-align: center;
`

const FormulaInput = styled.input`
    width: 300px;
`