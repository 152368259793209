function Ideas() {
    return <div>
        <h1>Pomysły</h1>
        <h2>Które się pojawiły. Ale nie wiadomo czy kiedykolwiek zostaną zrealizowane:</h2>
        <ul>
            <li>Miejsce gry, przeciwnik</li>
            <li>Wiele zestawów koszulek</li>
            <li>Edytowanie zdarzeń na liście poniżej</li>
            <li>Może jakieś ładniejsze graficzki...</li>
            <li>Przycisk 'pomoc'</li>
            <li>Alerty (np.: zawodnik XXX nie rzucił bramki od 3 minut. Albo zawodnik yyy zepsuł ostatnie 2 rzuty czy zawodnik zzzz gra od 20 minut bez zmiany)</li>
            <li>Wykres liniowy (oś x, na której zaznaczone będą wybrane wydarzenia)</li>
            <li>Zaznaczanie przerwy w czasie meczu (zatrzymanie zegara)</li>
            <li>Guzik 'cofnij'</li>
            <li>Export bezpośrednio do Spreadsheet</li>
            <li>Logowanie się - użytkownik+hasło (historia meczów i wszelkie możliwości wynikające z archiwum)</li>
            <li>Cykliczne zapisywanie stanu gry (na wypadek zamknięcia przeglądarki)</li>
            <li>Możliwość zbierania statystyk offline (PWA)</li>
            <li>Tłumaczenie na inne języki</li>
            <li>Wprowadzanie wydarzeń głosem (wcześniej - wprowadzanie za pomocą klawiatury)</li>
        </ul>
        <h2>Zaimplementowane</h2>
        <h3>W wersji 0.13</h3>
        22.09.2024
        <ul>
            <li>Naprawa błędu - znów można przełączać się pomiędzy raportami</li>
        </ul>
        <h3>W wersji 0.12</h3>
        3.02.2024
        <ul>
            <li>Naprawa błędu związanego z filtrowaniem wierszy w statystykach</li>
            <li>Naprawa edycji wydarzeń</li>
        </ul>
        <h3>W wersji 0.11</h3>
        2.02.2024
        <ul>
            <li>Podanie i odebranie piłki nie są widoczne jako osobne akcje (tylko za pomocą ikonki piłki)</li>
            <li>Można odznaczyć ikonkę piłki - poprawa zachowania guzików piłki i wyboru zawodnika</li>
        </ul>
        <h3>W wersji 0.10</h3>
        <ul>
            <li>Śledzenie podań</li>
            <li>Drobne poprawki w wyglądzie aplikacji</li>
            <li>Aplikacja dostępna z nowych domen</li>
            <li>Nowy mechanizm tabelki raportów statystyki</li>
        </ul>
        <h3>W wersji 0.9</h3>
        <ul>
            <li>Tworzenie własnych raportów</li>
        </ul>
        <h3>W wersji 0.8</h3>
        <ul>
            <li>Zaznaczanie zawodników, którzy nie biorą udziału w meczu (żeby się nie pokazywali na liście)</li>
        </ul>
    </div>
}
Ideas.VIEW_NAME = "Ideas";
export default Ideas;