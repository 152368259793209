import React from "react";
import StatsContext, { StatsContextType } from "../../context/StatsContext";
import './GameView.css';
import GameViewPerPlayer from "./perPlayer/GameViewPerPlayer";
import GameViewStatic from "./static/GameViewStatic";

function GameView() {
    const props = React.useContext(StatsContext) as StatsContextType;

    if (props.gameView === "perPlayer") {
        return <GameViewPerPlayer></GameViewPerPlayer>
    } else if (props.gameView === "static") {
        return <GameViewStatic></GameViewStatic>
    } else {
        return <div>Unknown view: {props.gameView}</div>
    }
}
GameView.VIEW_NAME = "GameView";
export default GameView;